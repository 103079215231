<template>
  <ModalMain
    transition-name="none"
    @close="close"
  >
    <div class="px-4 md:px-7 pt-4 w-full">
      <article class="animate-fade-in">
        <div class="text-center block">
          <h1 v-if="mode === 'login'" class="text-2xl tracking-wide font-semibold mb-2 text-center animate-slide-down-fade-in01s">Sign In</h1>
          <h1 v-if="mode === 'verify'" class="text-2xl tracking-wide font-semibold mb-2 text-center animate-slide-down-fade-in01s">Verify</h1>
          <p v-if="mode === 'login'" class="text-slate-100 lg:text-xl mt-2 mb-6 leading-tight animate-slide-down-fade-in04s">Sign in with your verified email</p>
          <p v-if="mode === 'verify'" class="text-slate-100 lg:text-xl mt-2 mb-6 leading-tight animate-slide-down-fade-in04s">Check your email for your verification code</p>
          <input
            v-model="email"
            class="block relative w-full mb-4 bg-black/50 rounded-lg py-3 px-1 sm:px-3 border-2 outline-none focus:ring-0 autofill:bg-slate-800 appearance-none hover:appearance-none disabled:text-slate-100 text-sm text-center transition-all duration-200"
            name="Email"
            type="text"
            placeholder="Enter Email Address"
            autocomplete="off"
            :disabled="isLoading || mode !== 'login'"
            @keyup.enter="onSubmit"
          >
          <template v-if="mode === 'verify'">
            <h3 class="text-xl tracking-wide font-semibold mb-2 text-center animate-slide-down-fade-in01s">Enter Code</h3>
            <InputCode
              ref="CodeInput"
              class="animate-slide-down-fade-in01s mb-2"
              :length="codeLength"
              :disabled="isLoading"
              @update="codeUpdate"
            />
            <p v-if="codeRequestId" class="pb-3 text-2xs text-slate-50 animate-slide-down-fade-in01s">
              Request ID: {{ codeRequestId }}
            </p>
          </template>
          <p v-if="error" class="text-red mb-4">
            {{ error }}
          </p>
          <ButtonButton
            :is-loading="isLoading"
            size="md"
            :disabled="!canSubmit"
            class="w-full"
            @click.prevent.stop="onSubmit"
          >
            Continue
          </ButtonButton>
          <p
            v-if="mode === 'verify' && !maxAttemptsExceeded"
            class="animate-slide-down-fade-in01s text-slate-100 text-sm mt-5 cursor-pointer"
            @click.prevent.stop="mode = 'login';maxAttemptsExceeded = false"
          >
            Go Back
          </p>
        </div>
      </article>
    </div>
  </ModalMain>
</template>

<script>
import { mapWritableState, mapActions } from 'pinia';

export default defineComponent({
  name: 'ModalLoginEmail',
  data() {
    return {
      email: null,
      code: null,
      codeRequestId: null,
      loading: false,
      error: null,
      mode: 'login',
      codeLength: 6,
      maxAttemptsExceeded: false,
    };
  },
  computed: {
    ...mapWritableState(useUiStore, ['showLoginEmailModal',]),
    ...mapWritableState(useAuthStore, ['isUserLogged', 'isLoginPending', 'signUpData',]),
    isLoading() {
      return this.loading;
    },
    canSubmit() {
      switch (this.mode) {
        case 'login':
          return this.email && /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(this.email);
        case 'verify':
          return this.code?.length === this.codeLength && !this.maxAttemptsExceeded;
        default:
          return false;
      }
    },
  },
  watch: {
    isUserLogged(val) {
      if (val) { this.close(); }
    },
    mode() {
      this.error = null;
    },
  },
  mounted() {
    this.signUpData = {};

    if (this.email) { this.onSubmit(); }

    this.$gtmCustomEvent({ event: 'modal_show', action: 'modalOpen', modalName: 'LoginEmail', });
  },
  methods: {
    ...mapActions(useAuthStore, ['loginEmail', 'sendChallengeAnswer', 'logout',]),
    close(abort = false) {
      this.showLoginEmailModal = false;
      this.isLoginPending = false;
      this.maxAttemptsExceeded = false;

      if (abort) { this.logout(); }
    },
    resetInput() {
      this.code = '';
      this.$refs.CodeInput?.init();
    },
    async codeUpdate(val) {
      this.code = val;

      if (this.canSubmit) {
        await this.onSubmit();
      }
    },
    async onSubmit() {
      this.error = null;
      let pendingUser = null;

      try {
        this.loading = true;

        switch (this.mode) {
          case 'login':
            pendingUser = await this.loginEmail(this.email?.toLowerCase());

            if (pendingUser) {
              this.codeRequestId = pendingUser?.challengeParam?.requestId;
              this.mode = 'verify';
            }
            break;

          case 'verify':
            pendingUser = await this.sendChallengeAnswer(this.code.toString());

            if (!pendingUser.signInUserSession) { this.error = 'Invalid code, a new code has been sent, please try again.'; }

            this.codeRequestId = pendingUser?.challengeParam?.requestId;
            this.resetInput();
            break;
        }
      } catch (err) {
        if (err.name === 'NotAuthorizedException') {
          this.error = 'Invalid verification code or email address.';
          this.maxAttemptsExceeded = true;
          this.resetInput();
          return;
        }

        if (this.mode === 'verify') { this.resetInput(); }

        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
